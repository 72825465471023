import CustomSelect from '../../common/forms/Select.vue'

export default {
	data() {
		return {
		}
	},
	created(){
		if(!this.$store.state.company.company_edition_id && this.$store.state.user.user.mesentreprises.length){
			this.$store.commit('UI_SET_COMPANY_EDITION_ID', this.$store.state.user.user.mesentreprises[0].id);
		}
		this.setCompanyEdit();
	},
	methods: {
		setCompanyEdit(){
			let self = this
			let id  = this.$store.state.company.company_edition_id;
			if(id){

				let nom = '';

				if(this.$store.state.user.user.mesentreprises && this.$store.state.user.user.mesentreprises.length){
					this.$store.state.user.user.mesentreprises.forEach(function(entreprise){
						if(entreprise.id == self.$store.state.company.company_edition_id) nom = entreprise.nom;
					})
				}

				this.$store.dispatch('setCompany', {
					id,
					nom
				});
			}
		}
	},
	computed:{
		company_edition_id: {
			get () {
				return this.$store.state.company.company_edition_id
			},
			set (value) {
				this.$store.commit('UI_SET_COMPANY_EDITION_ID', value)
				this.setCompanyEdit()
			}
		},
		mesEntreprises(){
			let options = []
			this.$store.state.user.user.mesentreprises.forEach(function(entreprise){
				options.push({
					value: entreprise.id,
					label: entreprise.nom
				})
			})
			return options
		}

	},
	components: {
		CustomSelect
	}
}
