import Modal_Company from '../modal_company.vue'


export default {
	created(){
		
	},
	methods: {
	},
	components: {
		Modal_Company
	}
}