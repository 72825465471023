import Filter_monentreprise from './filter_monentreprise.vue'

import Subcontent_bienvenue from '../bienvenue/bienvenue.vue'
import Subcontent_edition_entreprise from './edition_entreprise.vue'
import Subcontent_edition_matieres from './edition_matieres.vue'


export default {
	created(){
		if(!this.$store.state.ui.monentreprise_subcontent) this.$store.commit('UI_SET_MONENTREPRISE_SUBCONTENT', {subcontent : 'bienvenue'});
	},
	methods: {
	},
	components: {
		Filter_monentreprise,
		Subcontent_bienvenue,
		Subcontent_edition_entreprise,
		Subcontent_edition_matieres
	},
	beforeDestroy(){
		this.$store.dispatch('setCompany', null)
	}
}